import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Select, Stack, Textarea, Checkbox, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react';
import TimePicker from 'react-time-picker';


function AddFeedingForm({ baby, isOpen, onClose, onSubmit }) {
  const currentTime = new Date();
  const formattedTime = `${currentTime.getHours().toString().padStart(2, '0')}:${currentTime.getMinutes().toString().padStart(2, '0')}`;
  const [time, setTime] = useState(formattedTime); // Default time

  const handleTimeChange = (newTime) => {
    setTime(newTime);
  };

  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    return `${hours}${minutes}`; // Convert to HHMM format
  };
  const [formData, setFormData] = useState({
    formula: 0,
    poop: '',
    baby_food: 0,
    note: '',
    d3: false,
    fe: false,
    probiotic: false,
    sime: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const translateBabyName = (engName) => {
    let babyNames = {
        lele: "乐乐",
        xixi: "嘻嘻"
    };
    return babyNames[engName];
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const now = new Date();
    
    // Format local date as YYYYMMDD
    const localDate = `${now.getFullYear()}${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}`;
  
    // Format local time as HHMM
    // const localTime = now.getHours().toString().padStart(2, '0') + now.getMinutes().toString().padStart(2, '0');

    const formattedTime = formatTime(time);

  
    const dataToSend = {
      ...formData,
      formula: parseFloat(formData.formula), // Ensure formula is treated as a number
      name: baby,
      date: localDate, // Use local formatted date
      time: formattedTime  // Use local formatted time (HHMM)
    };
  
    onSubmit(baby, dataToSend);
    onClose();
  };
  

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>为{translateBabyName(baby)}添加记录</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>选择时间</FormLabel>
                <TimePicker
                  onChange={handleTimeChange}
                  value={time}
                  disableClock={true} // Disables 12-hour clock view
                  format="HH:mm" // Ensure the time is in 24-hour format
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>奶粉量 (ml)</FormLabel>
                <Input
                  type="number"
                  name="formula"
                  value={formData.formula}
                  onChange={handleChange}
                  placeholder="输入奶粉量"
                />
              </FormControl>

              <FormControl>
                <FormLabel>便量</FormLabel>
                <Select name="poop" value={formData.poop} onChange={handleChange}>
                  <option value="">选择大便量</option>
                  <option value="L">大</option>
                  <option value="N">中</option>
                  <option value="S">小</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>辅食量 (ml)</FormLabel>
                <Input
                  type="number"
                  name="baby_food"
                  value={formData.baby_food}
                  onChange={handleChange}
                  placeholder="输入辅食量"
                />
              </FormControl>

              <FormControl>
                <FormLabel>备注</FormLabel>
                <Textarea
                  name="note"
                  value={formData.note}
                  onChange={handleChange}
                  placeholder="输入备注"
                />
              </FormControl>

              <FormControl>
                <Checkbox name="d3" isChecked={formData.d3} onChange={handleChange}>
                  D3 补充
                </Checkbox>
              </FormControl>

              <FormControl>
                <Checkbox name="fe" isChecked={formData.fe} onChange={handleChange}>
                  铁补充 (Fe)
                </Checkbox>
              </FormControl>

              <FormControl>
                <Checkbox name="probiotic" isChecked={formData.probiotic} onChange={handleChange}>
                  益生菌
                </Checkbox>
              </FormControl>

              <FormControl>
                <Checkbox name="sime" isChecked={formData.sime} onChange={handleChange}>
                  防胀气 (Sime)
                </Checkbox>
              </FormControl>
            </Stack>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            保存
          </Button>
          <Button variant="ghost" onClick={onClose}>
            取消
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AddFeedingForm;
