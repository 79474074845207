import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Heading, Stack, Text, Table, Thead, Tbody, Tr, Th, Td, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import AddFeedingForm from './AddFeedingForm';
import EditFeedingForm from './EditFeedingForm';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

// Helper function to format the current date as 'YYYYMMDD'
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}${month}${day}`;
};

// Helper function to convert `sk` to HH:MM format
const formatTimeFromSK = (sk) => {
  const hours = sk.slice(0, 2);
  const minutes = sk.slice(2, 4);
  return `${hours}:${minutes}`;
};

// Helper function to format `YYYYMMDD` to `YYYY-MM-DD`
const formatDisplayDate = (date) => {
  const year = date.substring(0, 4);
  const month = date.substring(4, 6);
  const day = date.substring(6, 8);
  return `${year}-${month}-${day}`;
};

function FeedingsToday() {
  const todayDate = formatDate(new Date()); // Get today's date in 'YYYYMMDD' format
  const [leleFeedings, setLeleFeedings] = useState([]);
  const [xixiFeedings, setXixiFeedings] = useState([]);
  const [dailyTotals, setDailyTotals] = useState({ lele: { formula: 0, baby_food: 0 }, xixi: { formula: 0, baby_food: 0 } });
  const [addFeeding, setAddFeeding] = useState({ lele: false, xixi: false });
  const [editingFeeding, setEditingFeeding] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const [viewNoteModal, setViewNoteModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState('');
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(todayDate);


  

  // Fetch feedings when the component mounts
  useEffect(() => {
    axios.get(`https://5krlj5d2d8.execute-api.us-east-1.amazonaws.com/prod/feedings?date=${selectedDate}`)
      .then(response => {
        const { lele, xixi, lele_daily, xixi_daily } = response.data;
        setLeleFeedings(lele);
        setXixiFeedings(xixi);

        setDailyTotals({
          lele: {
            formula: lele_daily.formula,
            baby_food: lele_daily.baby_food,
          },
          xixi:{
            formula: xixi_daily.formula,
            baby_food: xixi_daily.baby_food,
          }
        });
      })
      .catch(error => console.error('Error fetching feeding data:', error));
  }, [selectedDate, refreshKey]);

  useEffect(() => {
    if (showCalendar) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showCalendar]);
  

  // Handle adding new feeding record
  const handleAddFeedingClick = (baby) => {
    setAddFeeding({ ...addFeeding, [baby]: true });
  };

  const handleReturnToToday = () => {
    setSelectedDate(todayDate); // Use the `todayDate` constant
  };

  // Handle form submission for adding feeding
  const handleFormSubmit = (baby, formData) => {
    axios.post('https://5krlj5d2d8.execute-api.us-east-1.amazonaws.com/prod/feedings', formData)
      .then(response => {
        const newFeeding = response.data.item;
        if (baby === 'lele') {
          setLeleFeedings([...leleFeedings, newFeeding]);
          setDailyTotals(prev => ({
            ...prev,
            lele: {
              formula: prev.lele.formula + newFeeding.formula,
              baby_food: prev.lele.baby_food + newFeeding.baby_food,
            },
          }));
        } else {
          setXixiFeedings([...xixiFeedings, newFeeding]);
          setDailyTotals(prev => ({
            ...prev,
            xixi: {
              formula: prev.xixi.formula + newFeeding.formula,
              baby_food: prev.xixi.baby_food + newFeeding.baby_food,
            },
          }));
        }
        setAddFeeding({ lele: false, xixi: false });
        setRefreshKey(prev => prev + 1);
      })
      .catch(error => console.error('Error adding feeding:', error));
  };

  // Handle editing feeding
  const handleEditFeedingClick = (feeding) => {
    setEditingFeeding(feeding);
  };

  const handleViewNoteClick = (feeding) => {
    setSelectedNote(feeding.note || '没有备注');
    setViewNoteModal(true);
  };

  // Handle form submission for editing feeding
  const handleEditFormSubmit = (feedingId, formData) => {
    axios.patch(`https://5krlj5d2d8.execute-api.us-east-1.amazonaws.com/prod/feedings/${feedingId}`, formData)
      .then(response => {
        const updatedFeeding = response.data.item;
        setLeleFeedings(prev => prev.map(f => f.fid === feedingId ? updatedFeeding : f));
        setXixiFeedings(prev => prev.map(f => f.fid === feedingId ? updatedFeeding : f));
        setEditingFeeding(null);
        setRefreshKey(prev => prev + 1);
      })
      .catch(error => console.error('Error editing feeding:', error));
  };

  return (
    <Box p={4}>
      <Heading mb={6} textAlign="center" color="yellow.500">
        当前日志: {formatDisplayDate(selectedDate)}
        <Button
            ml={4}
            colorScheme="blue"
            size="sm"
            onClick={() => setShowCalendar(true)}
        >
            查看历史记录
        </Button>
        <Button
            ml={4}
            colorScheme="green"
            size="sm"
            onClick={handleReturnToToday}
            disabled={selectedDate === todayDate}
        >
            回到今天
        </Button>
      </Heading>
      <Stack spacing={8}>
        {/* Lele's Feedings */}
        <Box>
          <Heading size="lg" mb={4} color="green.400">乐乐</Heading>
          {selectedDate === todayDate && (
            <Button colorScheme="green" onClick={() => handleAddFeedingClick('lele')} mb={4}>添加日志</Button>
          )}
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>时间</Th>
                <Th>配方奶</Th>
                <Th>便便</Th>
                <Th>辅食</Th>
                <Th>维生素D3</Th>
                <Th>铁剂</Th>
                <Th>益生菌</Th>
                <Th>防胀气</Th>
                <Th>操作</Th>
              </Tr>
            </Thead>
            <Tbody>
              {leleFeedings.map(feeding => (
                <Tr key={feeding.fid}>
                  <Td>{formatTimeFromSK(feeding.sk)}</Td>
                  <Td>{feeding.formula} ml</Td>
                  <Td>
                    {feeding.poop === "L" ? "大" : 
                    feeding.poop === "S" ? "小" : 
                    feeding.poop === "N" ? "中" : "无"}
                  </Td>
                  <Td>{feeding.baby_food} ml</Td>
                  <Td>{feeding.d3 ? "是" : "否"}</Td>
                  <Td>{feeding.fe ? "是" : "否"}</Td>
                  <Td>{feeding.probiotic ? "是" : "否"}</Td>
                  <Td>{feeding.sime ? "是" : "否"}</Td>
                  <Td>
                    <Button size="sm" colorScheme="yellow" onClick={() => handleEditFeedingClick(feeding)} mr={2}>
                      编辑
                    </Button>

                    <Button size="sm" colorScheme="blue" onClick={() => handleViewNoteClick(feeding)}>
                      查看备注
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Text mt={4}>全日总计奶粉: {dailyTotals.lele.formula} ml</Text>
          <Text>全日总计辅食: {dailyTotals.lele.baby_food} ml</Text>
        </Box>

        {/* Xixi's Feedings */}
        <Box>
          <Heading size="lg" mb={4} color="blue.400">嘻嘻</Heading>
          {selectedDate === todayDate && (
            <Button colorScheme="blue" onClick={() => handleAddFeedingClick('xixi')} mb={4}>添加日志</Button>
          )}
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>时间</Th>
                <Th>配方奶</Th>
                <Th>便便</Th>
                <Th>辅食</Th>
                <Th>维生素D3</Th>
                <Th>铁剂</Th>
                <Th>益生菌</Th>
                <Th>防胀气</Th>
                <Th>操作</Th>
              </Tr>
            </Thead>
            <Tbody>
              {xixiFeedings.map(feeding => (
                <Tr key={feeding.fid}>
                  <Td>{formatTimeFromSK(feeding.sk)}</Td>
                  <Td>{feeding.formula} ml</Td>
                  <Td>
                    {feeding.poop === "L" ? "大" : 
                    feeding.poop === "S" ? "小" : 
                    feeding.poop === "N" ? "中" : "无"}
                  </Td>
                  <Td>{feeding.baby_food} ml</Td>
                  <Td>{feeding.d3 ? "是" : "否"}</Td>
                  <Td>{feeding.fe ? "是" : "否"}</Td>
                  <Td>{feeding.probiotic ? "是" : "否"}</Td>
                  <Td>{feeding.sime ? "是" : "否"}</Td>
                  <Td>
                    <Button size="sm" colorScheme="yellow" onClick={() => handleEditFeedingClick(feeding)} mr={2}>
                      编辑
                    </Button>
                    <Button size="sm" colorScheme="blue" onClick={() => handleViewNoteClick(feeding)}>
                      查看备注
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Text mt={4}>全日总计奶粉: {dailyTotals.xixi.formula} ml</Text>
          <Text>全日总计辅食: {dailyTotals.xixi.baby_food} ml</Text>
        </Box>
      </Stack>
      {/* Add Feeding Form */}
      {addFeeding.lele && (
        <AddFeedingForm
            baby="lele"
            isOpen={addFeeding.lele}
            onClose={() => setAddFeeding({ lele: false, xixi: false })}
            onSubmit={handleFormSubmit}
        />
      )}
      {addFeeding.xixi && (
        <AddFeedingForm
            baby="xixi"
            isOpen={addFeeding.xixi}
            onClose={() => setAddFeeding({ lele: false, xixi: false })}
            onSubmit={handleFormSubmit}
        />
        )}

        {showCalendar && (
        <Box
            position="fixed"
            top={0}
            left={0}
            width="100vw"
            height="100vh"
            backgroundColor="rgba(0, 0, 0, 0.5)"
            display="flex"
            justifyContent="center"
            alignItems="center"
            zIndex={1000}
            backdropFilter="blur(5px)"
        >
            <Box bg="white" p={6} borderRadius="md" boxShadow="lg" textAlign="center">
            <Calendar
                onChange={(date) => {
                const formattedDate = formatDate(date);
                setSelectedDate(formattedDate);
                setShowCalendar(false);
                }}
                maxDate={new Date(new Date().setDate(new Date().getDate() - 1))} // Disable future dates
            />
            <Button mt={4} colorScheme="red" onClick={() => setShowCalendar(false)}>
                关闭
            </Button>
            </Box>
        </Box>
        )}



      {/* Edit Feeding Form */}
      {editingFeeding && (
        <EditFeedingForm
            feeding={editingFeeding}
            isOpen={Boolean(editingFeeding)} // Ensure it's a boolean value
            onClose={() => setEditingFeeding(null)}
            onSubmit={handleEditFormSubmit}
        />
        )}
      <Modal isOpen={viewNoteModal} onClose={() => setViewNoteModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>备注</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{selectedNote}</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={() => setViewNoteModal(false)}>关闭</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default FeedingsToday;
